export const TIME_RANGE = [
  {
    key: 0,
    label: 'Today',
    value: 'today : now',
    numericValue: 0,
  },
  {
    key: 1,
    label: 'Yesterday',
    value: 'Last day',
    numericValue: 1,
  },
  {
    key: 2,
    label: '7D',
    value: 'today - 7 day : now',
    numericValue: 7,
  },
  {
    key: 3,
    label: '15D',
    value: 'Last 15 days',
    numericValue: 15,
  },
  {
    key: 4,
    label: '30D',
    value: 'Last 30 days',
    numericValue: 30,
  },
  {
    key: 5,
    label: '2M',
    value: 'Last 2 months',
    numericValue: 61,
  },
  {
    key: 6,
    label: '3M',
    value: 'Last 3 months',
    numericValue: 92,
  },
];

export const comparePreviousDaysMenu = [
  {
    label: 'Previous Day',
    value: '1 day ago',
  },
  {
    label: 'Previous 2 Days',
    value: '2 day ago',
  },
  {
    label: 'Previous 3 Days',
    value: '3 day ago',
  },
  {
    label: 'Previous Week',
    value: '1 week ago',
  },
  {
    label: 'Previous Month',
    value: '1 month ago',
  },
  {
    label: 'Previous 3 Months',
    value: '3 months ago',
  },
  // {
  //   label: 'Previous Year',
  //   key: '7',
  //   value: '1 year ago',
  // },
  {
    label: 'Custom Range',
    value: 'custom',
  },
];

export const EXCLUDE_DATA_CONTROLS = [
  'Global Filters',
  'Global Metrics',
  'Global Breakdown',
  'Signal Time Compare',
];

export const INITIAL_SELECTED_ROWS_NUMBER = 8;

export const MAXIMUM_SELECTED_ROWS_NUMBER = 15;

export const CUSTOM_EVENT = 'custom_event';
export const EVENT = 'event';

export const LOGICAL_AND = 'AND';
export const LOGICAL_IN = 'IN';
export const IN = 'in';

export const INSIGHT = 'insight';

export const SCHEMA_API_TIMEOUT = 60 * 1000;

export enum PROJECT_ID {
  SUPPLY_ANDROID_PROJECT_ID = 1,
  FARMISO_PROJECT_ID = 2,
  METRIC_STORE_PROJECT_ID = 4,
}

export enum VISUALIZATION_TYPE {
  INSIGHTS = 'insights',
  FUNNELS = 'funnels',
  METRIC_STORE = 'metric_store',
}
